import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import PropTypes, { element } from 'prop-types';


import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { RoleApi } from "../../../apis/RoleApi";
import { PermissionsApi } from "../../../apis/PermissionsApi";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import _ from "lodash";
// import { RolePermissionAPI } from "apis/RolePermissionAPI";
import { useHistory } from "react-router-dom";

const Permissions = (props) => {
    const role_id = props.match.params.role_id;
    const [role, setRole] = useState('');
    const [allmodules, setAllModule] = useState([]);
    const [accessModules, setAccessModules] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getRoleById();
        getAllModule()
    }, []);

    const getRoleById = () => {
      RoleApi.getRoleById(role_id)
        .then(res => {
          setRole(res.data.data.name)
        }).catch(err => {
          console.log(err)
        })
    }
  
    const getAllModule = () => {
      const payload = {
        role_id: role_id
      }
     PermissionsApi.getPermissionByRoleId(payload)
        .then(res => {
          if(res.data.data){
           const permdata= res.data.data.filter(e => e.name.includes("*")).map(e =>{
              const childrens  = res.data.data
              .filter(e1 => !e1.name.includes("*") && (e1.name?.split(".")[0] == (e.name?.split(".*")[0])))
              .map(e2 => {
                return {
                  ...e2,
                  name:e2.name.toUpperCase().split(".").join(" ")
                }
              })
           
              return {
                ...e,
                name:e.name.toUpperCase().split(".")[0]+" All",
                is_dropdown: true,
                childrens
              }
            });

            setAllModule(permdata)
            setAccessModules(res.data.data);
          }else{
            setAllModule([]);
            setAccessModules([])
          }
          
        }).catch(err => {
          console.log(err)
        })
    }

  const onSaveUpdateRolePermission = () => {
    const payload = {
      role_id: parseInt(role_id),
      permissions: accessModules
    }
    PermissionsApi.setPermissionByRoleId(payload)
      .then(res => {
        if(res.data.data){
          history.push('/roles');
        }
      }).catch(err => {
        console.log(err)
      });
  }


  const filterCateoryChange = async (type, parentID, action = "", parentIndex, childIndex) => {
      if(type === 'parent' && parentID === 0){
        const allModulesCopy = _.cloneDeep(allmodules);
        const editModulesData = allModulesCopy[parentIndex];
        editModulesData.childrens.forEach(async (child, i) => {
          child.isChecked = !child.isChecked;
          child.isRead = !child.isRead;
          child.isCreate = !child.isCreate;
          child.isEdit = !child.isEdit;
          child.isDelete = !child.isDelete;
        });
        editModulesData.isChecked = !editModulesData.isChecked;
        allModulesCopy.splice(parentIndex, 1, editModulesData);
        setAllModule(allModulesCopy);

        const accessModulesCopy = _.cloneDeep(accessModules);
        const editAccessModulesData = accessModulesCopy[parentIndex];
        editAccessModulesData.childrens.forEach(async (child, i) => {
          child.isChecked = !child.isChecked;
          child.view = !child.view;
          child.add = !child.add;
          child.edit = !child.edit;
          child.delete = !child.delete;
        });
        editAccessModulesData.isChecked = !editAccessModulesData.isChecked;
        console.log(editAccessModulesData);
        accessModulesCopy.splice(parentIndex, 1, editAccessModulesData);
        setAccessModules(accessModulesCopy);
        
      }else if(type === 'child' && parentID !== 0){
        const allModulesCopy = _.cloneDeep(allmodules);
        const editModulesData = allModulesCopy[parentIndex];
        let count = 0;
        editModulesData.childrens.forEach(async (child, i) => {
          if(childIndex !== i){
            if(child.isChecked){
              count = count + 1;
            }
          }
        });
        if(!count){
          editModulesData.isChecked = !editModulesData.isChecked;
        }
        editModulesData.childrens[childIndex].isChecked = !editModulesData.childrens[childIndex].isChecked;
        editModulesData.childrens[childIndex].isRead = !editModulesData.childrens[childIndex].isRead;
        editModulesData.childrens[childIndex].isCreate = !editModulesData.childrens[childIndex].isCreate;
        editModulesData.childrens[childIndex].isEdit = !editModulesData.childrens[childIndex].isEdit;
        editModulesData.childrens[childIndex].isDelete = !editModulesData.childrens[childIndex].isDelete;
        allModulesCopy.splice(parentIndex, 1, editModulesData);
        setAllModule(allModulesCopy);

        const accessModulesCopy = _.cloneDeep(accessModules);
        const editAccessModulesData = accessModulesCopy[parentIndex];
        let count2 = 0;
        editAccessModulesData.childrens.forEach(async (child, i) => {
          if(childIndex !== i){
            if(child.isChecked){
              count2 = count2 + 1;
            }
          }
        });
        if(!count2){
          editAccessModulesData.isChecked = !editAccessModulesData.isChecked;
        }
        editAccessModulesData.childrens[childIndex].isChecked = !editAccessModulesData.childrens[childIndex].isChecked;
        editAccessModulesData.childrens[childIndex].view = !editAccessModulesData.childrens[childIndex].view;
        editAccessModulesData.childrens[childIndex].add = !editAccessModulesData.childrens[childIndex].add;
        editAccessModulesData.childrens[childIndex].edit = !editAccessModulesData.childrens[childIndex].edit;
        editAccessModulesData.childrens[childIndex].delete = !editAccessModulesData.childrens[childIndex].delete;
        accessModulesCopy.splice(parentIndex, 1, editAccessModulesData);
        setAccessModules(accessModulesCopy);

      }else if(type === 'action' && parentID !== 0){
        const allModulesCopy = _.cloneDeep(allmodules);
        const editModulesData = allModulesCopy[parentIndex];
        if(!editModulesData.isChecked){
          editModulesData.isChecked = !editModulesData.isChecked;
        }
        if(!editModulesData.childrens[childIndex].isChecked){
          editModulesData.childrens[childIndex].isChecked = !editModulesData.childrens[childIndex].isChecked;
        }
        if(action === "view"){
          editModulesData.childrens[childIndex].isRead = !editModulesData.childrens[childIndex].isRead;
        }else if(action === "add"){
          editModulesData.childrens[childIndex].isCreate = !editModulesData.childrens[childIndex].isCreate;
        }else if(action === "edit"){
          editModulesData.childrens[childIndex].isEdit = !editModulesData.childrens[childIndex].isEdit;
        }else if(action === "delete"){
          editModulesData.childrens[childIndex].isDelete = !editModulesData.childrens[childIndex].isDelete;
        }
        allModulesCopy.splice(parentIndex, 1, editModulesData);
        setAllModule(allModulesCopy);

        const accessModulesCopy = _.cloneDeep(accessModules);
        const editAccessModulesData = accessModulesCopy[parentIndex];
        if(!editAccessModulesData.isChecked){
          editAccessModulesData.isChecked = !editAccessModulesData.isChecked;
        }
        if(!editAccessModulesData.childrens[childIndex].isChecked){
          editAccessModulesData.childrens[childIndex].isChecked = !editAccessModulesData.childrens[childIndex].isChecked;
        }
        if(action === "view"){
          editAccessModulesData.childrens[childIndex].view = !editAccessModulesData.childrens[childIndex].view;
        }else if(action === "add"){
          editAccessModulesData.childrens[childIndex].add = !editAccessModulesData.childrens[childIndex].add;
        }else if(action === "edit"){
          editAccessModulesData.childrens[childIndex].edit = !editAccessModulesData.childrens[childIndex].edit;
        }else if(action === "delete"){
          editAccessModulesData.childrens[childIndex].delete = !editAccessModulesData.childrens[childIndex].delete;
        }
        console.log(editAccessModulesData);
        accessModulesCopy.splice(parentIndex, 1, editAccessModulesData);
        setAccessModules(accessModulesCopy);
      }
  };

  const onChange = (id,value) => {
    const data = accessModules;
    const permissionModel = data.find(e => e.id == id);
    const permissionModelName = permissionModel.name.split(".")[0];
    const isParent = permissionModel.name == permissionModelName+".*";
   
    const newData = data?.map(e => {
      if(e.id == id || (isParent && e.name?.includes(permissionModelName))){
        return {
          ...e,
          has:value
        }
      }else{
        if(e.id != id &&  e.name == permissionModelName+".*"){
          return {
            ...e,
            has:false
          }
        }
        return e;
      }
    })

    const permdata= newData.filter(e => e.name.includes("*")).map(e =>{
      const childrens  = newData
      .filter(e1 => !e1.name.includes("*") && (e1.name?.split(".")[0] == (e.name?.split(".*")[0])))
      .map(e2 => {
        return {
          ...e2,
          name:e2.name.toUpperCase().split(".").join(" ")
        }
      })
   
      return {
        ...e,
        name:e.name.toUpperCase().split(".")[0]+" All",
        is_dropdown: true,
        childrens
      }
    });

    setAllModule(permdata)
    setAccessModules(newData)
  }  

  return (
    <React.Fragment>
      <div className="page-content">
      <Breadcrumbs title={props.t("Role")} breadcrumbItem={props.t("Edit") + " " + props.t("Role")} />
        <Container fluid={true}>
       
          <Row>

            <Col lg={12}>
              <Card>
                <CardBody >
                

                  <form>
                    <Col sm={6}>
                    <div className="mb-3">
                      <Label className="form-label">{props.t("Role")}</Label>
                      <Input
                        name="role"
                        placeholder={props.t("Role")}
                        type="text"
                        readOnly
                        value={role || ""}
                      />
                    </div>
                    </Col>

                    <div className="ul-list-style">
                    <Label className="form-label">{props.t("Data")} { props.t("Modules")}</Label>
                        <ul>
                          {
                            allmodules?.map((menu, i) => {
                              if (!menu.is_dropdown) {
                                return (
                                  <>
                                    <li>

                                      <span className="caret">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name={`module_name[${i}]`}
                                            value={JSON.stringify(menu)}
                                            onMouseUp={(e) => {
                                              onChange(menu.id,!e.target.checked)
                                             }}
                                            checked={menu.has}
                                            id={`parent_${menu.id}`}
                                          />
                                          <label className="form-check-label" htmlFor="defaultCheck1">{menu.name}</label>
                                        </div></span>
                                    </li>
                                  </>
                                )

                              } else {

                                return (
                                  <>
                                    <li>
                                      <span className="caret">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name={`module_name[${i}]`}
                                            value={JSON.stringify(menu.id)}
                                            onMouseUp={(e) => {
                                              onChange(menu.id,!e.target.checked)
                                             }}
                                            checked={menu.has}
                                            id={`parent_${menu.id}`}
                                          />
                                          <label className="form-check-label" htmlFor="defaultCheck1" >{menu.name}</label>
                                        </div></span>

                                     {
                                        menu.childrens.map((childmenu, j) => {
                                          return (
                                            <ul className="nested" key={j}>
                                              <li>
                                                <span className="caret">
                                                  <div className="form-check">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      name={`module_name[${i}][${j}]`}
                                                      value={JSON.stringify(childmenu.id)}
                                                      onMouseUp={(e) => {
                                                       onChange(childmenu.id,!e.target.checked)
                                                      }}
                                                      checked={childmenu.has}
                                                      id={`child_${menu.id}`}
                                                    />
                                                    <label className="form-check-label" htmlFor="defaultCheck1" > {childmenu.name}</label>
                                                  </div></span>
                                                
                                              </li>
                                            </ul>

                                          )

                                        })

                                      } 
                                    </li>

                                  </>
                                )

                              }
                            })
                          }


                        </ul>
                      </div>
                   
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="button" color="primary" onClick={onSaveUpdateRolePermission}>
                      {props.t("Submit")}
                      </Button>{" "}
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};



export default withTranslation()(Permissions);
