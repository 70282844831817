import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { loginUser, socialLogin } from "../../store/actions";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.png";
import { facebook, google } from "../../config/config";
import { Token } from "../../Token";
import { AuthAPI } from "../../apis/AuthAPI";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

const Login = (props) => {
  document.title = "Login | Salon";
  const history = useHistory();
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Please Enter Your Email")),
      password: Yup.string().required(props.t("Please Enter Your Password")),
    }),
    onSubmit: (values) => {
      loginUser(values);
    },
  });

  const loginUser = (value) => {
    // setLoader(true)
    AuthAPI.login(value)
      .then((res) => {
        // setLoader(false)
        Token.saveToken(res.data.data, res.data.data.token);
        if (res.status == "200") {
          // localStorage.setItem("I18N_LANGUAGE", res.data.superAdmin.language);
          console.log(res)
          // Swal.fire({
          //   text: `Welcome back ${res.data.data.name}`,
          //   icon: "success",
          //   imageAlt: "success image",
          //   // confirmButtonColor: '#00CA84'
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     window.location.href = "/dashboard";
          //   }
          // });
          // toastr.success(`Welcome back ${res.data.user.name}`, '');
          history.push("/");
          window.location.href = '/dashboard';
        } else {
          Swal.fire({
            text: error.message,
            icon: "error",
            imageAlt: "error image",
            // confirmButtonColor: '#00CA84'
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          text: error.message,
          icon: "error",
          imageAlt: "error image",
          // confirmButtonColor: '#00CA84'
        });
      });
  };

  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          {props.t("Welcome Back !")}
                        </h5>
                        <p>{props.t("Sign in to continue")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            width="60%"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={props.t("Enter Email")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder={props.t("Enter Password")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                              validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Link to="/forgot-password" className="">Forgot Password?</Link>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {props.t("Log In")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
};

export default withTranslation()(Login);
