import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import * as url from "../../helpers/common_helper";


import toastr from "toastr";
import "toastr/build/toastr.min.css";
import PropTypes from "prop-types";
// import { RoleApi } from "../../apis/RoleApi";
// import { RoleModuleApi } from "../../apis/RoleModuleApi";

import { useHistory } from "react-router-dom";
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
// import i18n from "../../i18n";
// import constants from "../../config/constants";
import config from "config/config";

// import { CompaniesApi } from "apis/CompaniesApi";
import Breadcrumb from "components/Common/Breadcrumb";
import { SalonApi } from "apis/SalonApi";

const ViewSalon = (props) => {
    const salon_id = props.match.params.salon_id;
    const [files1, setFiles1] = useState([]);
    const [salon, setSalon] = useState("");
    const [salonstatus, setSalonStatus] = useState("");
    const [documents, setDocuments] = useState([])


    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);

    const toggleimg1 = () => setModal(!modal);
    const toggleimg2 = () => setModal2(!modal2);
    const toggleimg3 = () => setModal3(!modal3);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT,
    });



    const getSalonById = () => {
        // console.log(salon_id);
        SalonApi.getSalonById({ salon_id })
            .then((res) => {
                // console.log(res)
                setSalonStatus(res.data.data.status)
                setSalon(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getSalonById();
        // getAllOfferTypes(activeData);
    }, []);

    // Form validation
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name_en: salon.name ?? "",
            address: salon.address ?? "",
            registration_number: salon.registration_number ?? "",
            city: salon.city ?? "",
            geo_location_link: salon.geo_location_link ?? "",
            image: salon.image ?? "",
            icon: salon.icon ?? "",
            service_at: salon.service_at ?? "",
            start_time: salon.start_time ?? "",
            end_time: salon.end_time ?? "",
            price_range: salon.price_range ?? "",
            commercial_reg_image: salon.commercial_reg_image ?? "",
            vat_reg_certificate: salon.vat_reg_certificate ?? "",
            status: salonstatus || "",
            reason: salon.reason ?? "",
            created_by: salon.created_by ?? "",
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().when([], {
                is: () => salonstatus == "Rejected",
                then: Yup.string().required(props.t("Required-Message")),
                otherwise: Yup.string().notRequired(),
            }),
            status: Yup.string().required(props.t("Required-Message")),
        }),
        onSubmit: (values) => {
            const salon = {
                salon_id: salon_id,
                ...values,
            };
            SalonApi.updateSalonStatus(salon)
                .then((res) => {
                    if (res.data.message && res.status == 200) {
                        // Swal.fire({
                        //   text: res.data.message,
                        //   icon: "success",
                        //   imageAlt: "success image",
                        // }).then((result) => {
                        //   if (result?.isConfirmed) {
                        //     history.push("/salons?status=" + salon?.status);
                        //     formClear();
                        //     setFiles1();
                        //   }
                        // });

                        history.push("/salons?status=" + salon?.status);
                        formClear();
                        setFiles1();
                    } else {
                        toastr.error(res?.data?.message, "");
                    }
                })
                .catch(function (error) {
                    if (error?.response?.data?.message) {
                        toastr.error(error?.response?.data?.message, "");
                    } else {
                        toastr.error(
                            error?.response?.data?.error?.errors?.map(
                                (item, v) => `${item.msg}<br>`
                            ),
                            ""
                        );
                    }
                });
        },
    });

    const formClear = () => {
        validationType.resetForm();
        setFiles1([]);
    };

    document.title = props.t("View Salon") + " | " + props.t("Salon");

    // console.log(salonstatus)

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb
                    title={props.t("Salon")}
                    breadcrumbItem={props.t("View") + " " + props.t("Salon")}
                />
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Col sm={12}>
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validationType.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">
                                                        {props.t("Name_Eng")}<span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="name_en"
                                                        placeholder={props.t("Name_Eng")}
                                                        type="text"
                                                        readOnly
                                                        disabled
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.name_en || ""}
                                                        invalid={
                                                            validationType.touched.name_en &&
                                                                validationType.errors.name_en
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validationType.touched.name_en &&
                                                        validationType.errors.name_en ? (
                                                        <FormFeedback type="invalid">
                                                            {validationType.errors.name_en}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Registration Number")}<span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="registration_number"
                                                            placeholder={props.t("Registration Number")}
                                                            readOnly
                                                            disabled
                                                            type="text"
                                                            onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            value={
                                                                validationType.values.registration_number || ""
                                                            }
                                                            invalid={
                                                                validationType.touched.registration_number &&
                                                                    validationType.errors.registration_number
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validationType.touched.registration_number &&
                                                            validationType.errors.registration_number ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.registration_number}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col sm={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Address")}<span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="address"
                                                            placeholder={props.t("Address)")}
                                                            type="textarea"
                                                            readOnly
                                                            disabled
                                                            onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            value={validationType.values.address || ""}
                                                            invalid={
                                                                validationType.touched.address &&
                                                                    validationType.errors.address
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validationType.touched.address &&
                                                            validationType.errors.address ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.address}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Geo Location")}<span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="geo_location_link"
                                                            placeholder={props.t("Geo Location")}
                                                            type="text"
                                                            readOnly
                                                            disabled
                                                            onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            value={
                                                                validationType.values.geo_location_link || ""
                                                            }
                                                            invalid={
                                                                validationType.touched.geo_location_link &&
                                                                    validationType.errors.geo_location_link
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validationType.touched.geo_location_link &&
                                                            validationType.errors.geo_location_link ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.geo_location_link}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col sm={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Service At")}<span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="service_at"
                                                            placeholder={props.t("Service At")}
                                                            type="text"
                                                            readOnly
                                                            disabled
                                                            onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            value={validationType.values.service_at || ""}
                                                            invalid={
                                                                validationType.touched.service_at &&
                                                                    validationType.errors.service_at
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validationType.touched.service_at &&
                                                            validationType.errors.service_at ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.service_at}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Start Time")}<span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="start_time"
                                                            placeholder={props.t("Start Time")}
                                                            type="text"
                                                            readOnly
                                                            disabled
                                                            onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            value={url.TimeFormat(validationType.values.start_time) === "Invalid date" ? "-" : url.TimeFormat(validationType.values.start_time) || ""}
                                                            invalid={
                                                                validationType.touched.start_time &&
                                                                    validationType.errors.start_time
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validationType.touched.start_time &&
                                                            validationType.errors.start_time ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.start_time}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col sm={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("End Time")}<span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="end_time"
                                                            placeholder={props.t("End Time")}
                                                            type="text"
                                                            readOnly
                                                            disabled
                                                            onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            value={url.TimeFormat(validationType.values.end_time) === "Invalid date" ? "-" : url.TimeFormat(validationType.values.end_time) || ""}
                                                            invalid={
                                                                validationType.touched.end_time &&
                                                                    validationType.errors.end_time
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validationType.touched.end_time &&
                                                            validationType.errors.end_time ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.end_time}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Price Range")}<span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="price_range"
                                                            placeholder={props.t("Start Time")}
                                                            type="text"
                                                            readOnly
                                                            disabled
                                                            onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            value={validationType.values.price_range || ""}
                                                            invalid={
                                                                validationType.touched.price_range &&
                                                                    validationType.errors.price_range
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validationType.touched.price_range &&
                                                            validationType.errors.price_range ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.price_range}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row lg={12}>
                                                <Col sm={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Commercial Registration")}<span className="text-danger">*</span>
                                                        </Label>
                                                        {/* <Input
                              name="commercial_reg_image"
                              placeholder={props.t("Commercial Registration")}
                              type="text"
                              readOnly
                              disabled
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.commercial_reg_image || ""}
                              invalid={
                                validationType.touched.commercial_reg_image &&
                                  validationType.errors.commercial_reg_image
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.commercial_reg_image &&
                              validationType.errors.commercial_reg_image ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.commercial_reg_image}
                              </FormFeedback>
                            ) : null} */}
                                                        <img src={`${salon.commercial_reg_image}`} height="100px" width="100px" onClick={toggleimg1} />
                                                        <Modal isOpen={modal} toggle={toggleimg1}>
                                                            <ModalHeader>Commercial Reg Image</ModalHeader>
                                                            <ModalBody>
                                                                <img src={`${salon.commercial_reg_image}`} height="500px" width="400px" />
                                                            </ModalBody>
                                                        </Modal>

                                                    </div>
                                                </Col>

                                                <Col sm={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Image")}<span className="text-danger">*</span>
                                                        </Label>
                                                        {/* <Input
                              name="image"
                              placeholder={props.t("Image")}
                              type="text"
                              readOnly
                              disabled
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.image || ""}
                              invalid={
                                validationType.touched.image &&
                                  validationType.errors.image
                                  ? true
                                  : false
                              }
                            /> */}
                                                        {/* {validationType.touched.image &&
                              validationType.errors.image ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.image}
                              </FormFeedback>
                            ) : null} */}
                                                        <img src={`${salon.image}`} height="100px" width="100px" onClick={toggleimg2} />

                                                        <Modal isOpen={modal2} toggle={toggleimg2}>
                                                            <ModalHeader>Image</ModalHeader>
                                                            <ModalBody>
                                                                <img src={`${salon.image}`} height="500px" width="400px" />
                                                            </ModalBody>
                                                        </Modal>
                                                    </div>
                                                </Col>
                                                <Col sm={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Icon")}<span className="text-danger">*</span>
                                                        </Label>
                                                        {/* <Input
                              name="icon"
                              placeholder={props.t("Icon")}
                              type="text"
                              readOnly
                              disabled
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.icon || ""}
                              invalid={
                                validationType.touched.icon &&
                                  validationType.errors.icon
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.icon &&
                              validationType.errors.icon ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.icon}
                              </FormFeedback>
                            ) : null} */}
                                                        <img src={`${salon.icon}`} height="100px" width="100px" onClick={toggleimg3} />
                                                        <Modal isOpen={modal3} toggle={toggleimg3}>
                                                            <ModalHeader>Icon</ModalHeader>
                                                            <ModalBody>
                                                                <img src={`${salon.icon}`} height="500px" width="400px" />
                                                            </ModalBody>
                                                        </Modal>

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {props.t("Status")}<span className="text-danger">*</span>
                                                        </Label>
                                                        <select
                                                            name="status"
                                                            className="form-select"
                                                            placeholder={props.t("Status")}
                                                            type="select"

                                                            onChange={(e) => { setSalonStatus(e.target.value) }}
                                                            onBlur={validationType.handleBlur}
                                                            value={salonstatus || ""}
                                                            disabled={true}
                                                            invalid={
                                                                validationType.touched.status &&
                                                                    validationType.errors.status
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            <option value="0">{props.t("Select Status")}</option>
                                                            {documents?.length > 0 ? null : <option value="Approved">{props.t("Approved")}</option>}

                                                            <option value="Pending">{props.t("Pending")}</option>
                                                            <option value="Rejected">{props.t("Rejected")}</option>
                                                        </select>
                                                        {validationType.touched.status &&
                                                            validationType.errors.status ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.status}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Form>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

ViewSalon.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ViewSalon);
