// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../../../components/Common/TableContainer";
import DataTable from "react-data-table-component";
import { RoleApi } from "../../../apis/RoleApi";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import config from "../../../config/config";

import { Link } from "react-router-dom";
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";

import Swal from "sweetalert2";
import { AdminApi } from "apis/AdminApi";

function Users(props) {
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT
  });

  useEffect(() => {
    getUsers({ ...activeData });
  }, [props.match.params.role_id]);

  const columns = [
    {
      name: props.t("ID"),
      selector: (row) => row.role_id,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Name"),
      selector: (row) => row.role,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: (row) => row.action,
      sort: "asc",
      width: 200,
    },
  ];

  const getUsers = (data) => {
    setLoading(true);
    AdminApi.getUsers({ ...data, role: props.match.params.role_id })
      .then((res) => {
        setActiveData({
          ...activeData,
          page: activeData.page,
          totalPage: res.data.meta.total,
          search: activeData.search,
          limit: activeData.limit,
        });
        let data = [];
        res.data.data.forEach((v, i) => {
          data[i] = {
            role_id: v.id,
            role: v.name,
            action: (
              <>
                <Link style={{ marginRight: "15px" }} to={`/edit-role/${v.id}`}>
                  <i className=" fas fa-edit" id="edittooltip" />
                </Link>
                <i
                  className="mdi mdi-delete font-size-18"
                  style={{ color: "red" }}
                  id="deletetooltip"
                  onClick={() => onDelete(v.id)}
                />
              </>
            ),
          };
        });
        setRoles(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        RoleApi.deleteRole(id)
          .then((res) => {
            Swal.fire("Deleted!", res.data.message, "success");
            getUsers(activeData);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleChange = (v) => {
    const data = {
      ...activeData,
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    };
    setActiveData(data);
    getUsers(data);
  };

  const handleRowChange = (v) => {
    const data = {
      ...activeData,
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    };
    setActiveData(data);
    getUsers(data);
  };

  //meta title
  document.title = props.t("Roles") + " | " + props.t("Salon");

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Profile")}
          breadcrumbItem={props.t(props.match.params.role_id)}
        />
        <Link to="/add-role" className="btn btn-primary w-xs btn-right">
          {props.t("Add")}
        </Link>
      </div>

      <div className="container-fluid">
        <DataTable
          className="table-bordered"
          progressPending={loading}
          columns={columns}
          data={roles}
          pagination
          paginationServer
          paginationTotalRows={activeData.totalPage}
          paginationPerPage={activeData.limit}
          defaultSortFieldID={1}
          onChangeRowsPerPage={(value) => handleRowChange(value)}
          onChangePage={(value) => handleChange(value)}
          sortable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          highlightOnHover
        />
      </div>
    </div>
  );
}
Users.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
};
export default withTranslation()(Users);
