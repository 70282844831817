module.exports = {
  // API_URL: "http://api.salonappteam.online/api/v1",
  // API_URL: "http://127.0.0.1:8000/api/v1",
  // API_URL: "http://139.59.9.194/salonapp_api/api/v1",
  API_URL: "https://api.salondev.ilserver.cloud/api/v1",
  LIMIT: 10,
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
}