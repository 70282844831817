import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { RoleModuleApi } from "../../apis/RoleModuleApi";

const SidebarContent = props => {
  const [rolemodules, setRoleModule] = useState([]);
  const ref = useRef();
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
        new MetisMenu("#side-menu");
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    };

    const getAllModule = () => {
      RoleModuleApi.getAllModule()
        .then(res => {
          if (res.data.data) {
            setRoleModule(res.data.data);
            setTimeout(() => {
              initMenu();
            }, 100)
          }
        }).catch(err => {
          console.log(err)
        })
    }
    if(rolemodules.length <= 0)
        getAllModule();
    else
        initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
                {/* <li className="menu-title mm-active">
                    <a href="#">
                        <i className="bx bx-home-circle"></i>
                        <span>{props.t("Dashboard")}</span>
                    </a>
                </li> */}
                {rolemodules.map((menu, i) => {
                    if (!menu.hierarchy) {
                        return (
                            <li key={`main_${i}`}>
                                <Link to={`/${menu.link}`} >
                                    <i className={menu.icon}></i>
                                    <span>{menu.label}</span>
                                </Link>
                            </li>
                        )
                    } else {
                        return (
                            <li key={`main_${i}`}>
                                <Link to="/#" className="has-arrow">
                                    <i className={menu.icon}></i>
                                    <span>{menu.label}</span>
                                </Link>
                                <ul className="sub-menu" key={`ul_${i}`}>
                                    {menu?.childrensMenu?.map((childmenu, j) => {
                                        return (
                                            <li key={`child_${j}`}>
                                                <Link to={`/${childmenu.link}`}>{childmenu.label}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        )
                    }
                })}
            </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
