import usFlag from "../assets/images/flags/us.jpg"
import arFlag from "../assets/images/flags/arabic.jpg"

const languages = {
  eng: {
    label: "English",
    flag: usFlag,
  },
  arab: {
    label: "Ar",
    flag: arFlag,
  },
}

export default languages
