export const getTimezoneInfo = () => {

    const timezoneName = new Date().toLocaleDateString(undefined, {day:'2-digit',timeZoneName: 'long' }).substring(4).match(/\b(\w)/g).join('');
  
    const date = new Date();
    const timezoneOffset = -date.getTimezoneOffset();
    const offsetHours = String(Math.floor(Math.abs(timezoneOffset / 60))).padStart(2, '0');
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(2, '0');
    const sign = timezoneOffset >= 0 ? '+' : '-';
    const formattedOffset = `${sign}${offsetHours}:${offsetMinutes}`;
  
    return { timezoneName, formattedOffset };
  };
  