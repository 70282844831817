import React from "react";
import { Redirect } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Dashboard from "../pages/Dashboard/index";

import ProfileRoles from "../pages/Profile/Role/Roles";
import Users from "../pages/Profile/Users/Users";
// import ProfileAddRoles from "../pages/Profile/Role/AddRoles";
// import ProfileEditRoles from "../pages/Profile/Role/EditRoles";

import Salons from "pages/Salon/Salons";
import Categories from "pages/Category/Categories";
import Services from "pages/Service/Services";
import SubServices from "pages/SubService/SubServices";
import Permissions from "pages/Profile/Permission/Permissions";
import SalonsEdit from "pages/Salon/SalonsEdit";
import PageContent from "pages/Setting/PageContent";
import EditPageContent from "pages/Setting/EditPageContent";
import AllSalon from "pages/Salon/AllSalon";
import ViewSalon from "pages/Salon/ViewSalon";

const authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/roles", component: ProfileRoles },
    { path: "/users/:role_id", component: Users },
    { path: "/permissions/:role_id", component: Permissions },
    { path: "/salons", component: Salons },
    { path: "/edit-salon/:salon_id", component: SalonsEdit },
    { path: "/view-salon/:salon_id", component: ViewSalon },
    { path: "/categories", component: Categories },
    { path: "/services", component: Services },
    { path: "/sub-services", component: SubServices },
    { path: "/page-content", component: PageContent },
    { path: "/edit-page-content/:id", component: EditPageContent },
    { path: "/all-salons", component: AllSalon }
];

const publicRoutes = [
    { path: "/", component: Login },
    { path: "/login", component: Login },
    { path: "/register", component: Register },
    { path: "/logout", component: Logout },
    { path: "/forgot-password", component: ForgetPwd },
];

export { authProtectedRoutes, publicRoutes };
