import React, { useMemo, useEffect, useState } from "react";

import {
    Form,
    Card,
    CardBody,
    Col,
    Row,
    Label,
    Input,
    FormFeedback,
    Button,
    Container,
} from "reactstrap";

import { useParams } from "react-router-dom";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { CMSApi } from "apis/CMSApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom"

const EditPageContent = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const cms_page_id = id;
    const [fetchCMSPage, setFetchCMSPage] = useState([]);
    const [contentEn, setContentEn] = useState(() => EditorState.createEmpty());
    const [contentAr, setContentAr] = useState(() => EditorState.createEmpty());
    const [convertedContentEn, setConvertedContentEn] = useState(null);
    const [convertedContentAr, setConvertedContentAr] = useState(null);

    //meta title
    document.title = "PageContent"

    useEffect(() => {
        getCMSById()
    }, []);

    const getCMSById = () => {
        let CMSPageId = { "cms_page_id": cms_page_id };
        // console.log(cms_page_id)
        CMSApi.getCMSPageById({ cms_page_id })
            .then(res => {
                setFetchCMSPage(res.data.data)
                console.log()
                setContentEn(EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(res.data.data.content_en)
                    )
                ))
                setContentAr(EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(res.data.data.content_ar)
                    )
                ))
            }).catch(err => {
                console.log(err)
            })
    }

    const handleEditorContentEnChange = (state) => {
        setContentEn(state);
        convertContentEnTextToHTML();
    }
    const convertContentEnTextToHTML = () => {
        let currentContentEnTextAsHTML = convertToHTML(contentEn.getCurrentContent());
        setConvertedContentEn(currentContentEnTextAsHTML);
    }

    const handleEditorContentArChange = (state) => {
        setContentAr(state);
        convertContentArTextToHTML();
    }
    const convertContentArTextToHTML = () => {
        let currentContentArTextAsHTML = convertToHTML(contentAr.getCurrentContent());
        setConvertedContentAr(currentContentArTextAsHTML);
    }

    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            name_en: fetchCMSPage ? fetchCMSPage.name_en : "",
            name_ar: fetchCMSPage ? fetchCMSPage.name_ar : "",
            content_en: fetchCMSPage ? fetchCMSPage.content_en : "",
            content_ar: fetchCMSPage ? fetchCMSPage.content_ar : "",
        },

        validationSchema: Yup.object().shape({
            name_en: Yup.string().required(
                props.t("Required-Message")
            ),
            name_ar: Yup.string().required(
                props.t("Required-Message")
            ),

        }),
        onSubmit: (values) => {
            const data = new FormData();
            data.append('name_en', values.name_en);
            data.append('name_ar', values.name_ar);
            data.append('content_en', convertedContentEn ? convertedContentEn : values.content_en);
            data.append('content_ar', convertedContentAr ? convertedContentAr : values.content_ar);
            data.append('cms_page_id', cms_page_id);
            CMSApi.updateCMSPage(data)
                .then(res => {
                    if (res.data.message) {
                        // Swal.fire({
                        //     text: res.data.message,
                        //     icon: 'success',
                        //     imageAlt: 'success image',
                        // }).then((result) => {
                        //     if (result.isConfirmed) {
                        //         history.push('/page-content');
                        //     }
                        // });
                        history.push('/page-content');
                    } else {
                        toastr.error(res.message, '');
                    }
                }).catch(function (error) {
                    if (error?.response?.data?.message) {
                        toastr.error(error?.response?.data?.message, '');
                    }
                    else {
                        toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                    }
                });

        }
    });

    console.log(cms_page_id)
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("CMS")} breadcrumbItem={props.t("Edit") + " " + props.t("Page_Content")} />
                    <Row>
                        <Col className="col-12">

                            <Button style={{ float: "right", marginBottom: "20px", }}
                                color="primary"
                                className="waves-effect waves-light  pull-right"
                                onClick={() => history.goBack()}
                            >
                                {props.t("Back")}
                            </Button>

                        </Col>
                        <Col>

                            <Card>
                                <CardBody>
                                    <Form method="post" className="wysiwyg-custom" onSubmit={(e) => {
                                        e.preventDefault();
                                        validationType.handleSubmit();
                                        return false;
                                    }}>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name_Eng")}</Label>
                                                <Input
                                                    name="name_en"
                                                    placeholder={props.t("Name_Eng")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name_en || ""}
                                                    invalid={
                                                        validationType.touched.name_en && validationType.errors.name_en ? true : false
                                                    }
                                                />
                                                {validationType.touched.name_en && validationType.errors.name_en ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name_en}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name_Arabic")}</Label>
                                                <Input
                                                    name="name_ar"
                                                    placeholder={props.t("Name_Arabic")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name_ar || ""}
                                                    invalid={
                                                        validationType.touched.name_ar && validationType.errors.name_ar ? true : false
                                                    }
                                                />
                                                {validationType.touched.name_ar && validationType.errors.name_ar ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name_ar}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Label className="form-label">{props.t("Content_En")}</Label>
                                        <Editor
                                            name="content_en"
                                            editorState={contentEn}
                                            onEditorStateChange={handleEditorContentEnChange}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                        />
                                        <Label className="form-label">{props.t("Content_Ar")}</Label>
                                        <Editor
                                            name="content_ar"
                                            editorState={contentAr}
                                            onEditorStateChange={handleEditorContentArChange}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                        />
                                        <Button color="primary" type="submit">
                                            {props.t("Update")}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(EditPageContent);
