import config from "../config/config";
import axios from "axios"
import { getToken } from "../Token";
import * as url from "../helpers/url_helper";
import { getTimezoneInfo } from '../helpers/timezone_helper';

const { timezoneName, formattedOffset } = getTimezoneInfo();

const token = getToken();
export const CMSApi = {
    getAllCMSPages: async (data) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}${url.GET_ALL_CMS_PAGES}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    createCMSPage: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_CREATE_CMS_PAGE}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getCMSPageById: async (data) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}${url.GET_CMS_PAGE}/${data?.cms_page_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    updateCMSPage: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_UPDATE_CMS_PAGE}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deleteCMSPage: async (data) => {
        var axiosConfig = {
            method: 'delete',
            url: `${config.API_URL}${url.POST_DELETE_CMS_PAGE}/${data?.id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    }
}