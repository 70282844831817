import config from "../config/config";
import axios from "axios"
import { getToken } from "../Token";
import * as url from "../helpers/url_helper";
import { getTimezoneInfo } from '../helpers/timezone_helper';

const { timezoneName, formattedOffset } = getTimezoneInfo();

const token = getToken();
export const SalonApi = {
    getAllSalons: async (data) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}${url.FETCH_ALL_SALONS}?status=${data?.status}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },

    getAllSalonsWithoutStatus: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.FETCH_ALL_SALONS_WITHOUT_STATUS}?status=${data?.status}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getSalonById: async (data) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}${url.FETCH_SALON}/${data?.salon_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    updateSalon: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.UPDATE_SALON}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },

    updateSalonStatus: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.UPDATE_SALON_STATUS}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
}