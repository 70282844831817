// Super Admin login
export const POST_ADMIN_LOGIN = "/auth/admin/login";

// Menus
export const FETCH_MENUS = "/menu";

// PROFILE
export const POST_CREATE_ROLE = "/admin/create-role";
export const POST_UPDATE_ROLE = "/admin/update-role";
export const POST_FETCH_ROLE = "/auth/admin/get-role";
export const POST_FETCH_ALL_ROLES = "/auth/admin/get-roles";

// PERMISSIONS
export const GET_PERMISSIONS_BY_ROLE = "/auth/admin/get-permissions";
export const SET_PERMISSIONS_BY_ROLE = "/auth/admin/set-permissions";


// USERS
export const POST_FETCH_ALL_USERS = "/auth/admin/get-users";

// Salons
export const FETCH_ALL_SALONS = "/superadmin/shops";
export const FETCH_ALL_SALONS_WITHOUT_STATUS = "/superadmin/shops"
export const FETCH_SALON = "/superadmin/shop/show";
export const UPDATE_SALON = "/superadmin/shop/update";
export const UPDATE_SALON_STATUS = "/superadmin/shop/salon_status_update"

// Categories
export const GET_CATEGORIES = "/superadmin/category";
export const POST_CREATE_CATEGORY = "";
export const GET_CATEGORY = "";
export const POST_UPDATE_CATEGORY = "";
export const POST_DELETE_CATEGORY = "";

// Services
export const GET_SERVICES = "/superadmin/services";
export const POST_CREATE_SERVICE = "";
export const GET_SERVICE = "";
export const POST_UPDATE_SERVICE = "";
export const POST_DELETE_SERVICE = "";

// Sub Services
export const GET_SUB_SERVICES = "/superadmin/subServices";
export const POST_CREATE_SUB_SERVICE = "";
export const GET_SUB_SERVICE = "";
export const POST_UPDATE_SUB_SERVICE = "";
export const POST_DELETE_SUB_SERVICE = "";













// System Setting
export const POST_FETCH_SYSTEM_SETTINGS = "/admin/fetch-system-setting";
export const POST_UPDATE_SYSTEM_SETTINGS = "/admin/update-system-setting";
export const POST_FETCH_REQUEST_INSTITUTIONS = "/admin/fetch-request-institutes";
export const POST_FETCH_REQUEST_SUB_INSTITUTIONS = "/admin/fetch-request-sub-institutes";
export const POST_FETCH_REQUEST_INSTITUTION_BY_ID = "/admin/fetch-request-institute-details";


//profile
export const POST_UPDATE_USER_LANGUAGE = "/admin/update-user-language";
export const POST_UPDATE_USER_PROFILE = "/admin/update-profile";
export const POST_UPDATE_USER_PASSWORD = "/admin/change-password";
//INSTITUTIONS
export const POST_CREATE_INSTITUTIONS = "/admin/create-institute";
//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";
//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";
//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";
//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";
//starred mail
export const GET_STARRED_MAILS = "/starredmails";
//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";
//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";
//Send mail
export const GET_SENT_MAILS = "/sentmails";
//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//INTERESTS
export const POST_FETCH_ALL_INTERESTS = "/admin/fetch-interests";
export const POST_CREATE_INTEREST = "/admin/create-interest";
export const POST_FETCH_INTEREST = "/admin/fetch-interest";
export const POST_UPDATE_INTEREST = "/admin/update-interest";
export const POST_DELETE_INTEREST = "/admin/delete-interest";

//ORGANIZATIONTYPES
export const POST_FETCH_ALL_ORGANIZATIONTYPES = "/admin/fetch-organization-types";
export const POST_CREATE_ORGANIZATIONTYPE = "/admin/create-organization-type";
export const POST_FETCH_ORGANIZATIONTYPE = "/admin/fetch-organization-type";
export const POST_UPDATE_ORGANIZATIONTYPE = "/admin/update-organization-type";
export const POST_DELETE_ORGANIZATIONTYPE = "/admin/delete-organization-type";

//SERVICETYPES
export const POST_FETCH_ALL_SERVICETYPES = "/admin/fetch-service-types";
export const POST_CREATE_SERVICETYPE = "/admin/create-service-type";
export const POST_FETCH_SERVICETYPE = "/admin/fetch-service-type";
export const POST_UPDATE_SERVICETYPE = "/admin/update-service-type";
export const POST_DELETE_SERVICETYPE = "/admin/delete-service-type";

//PROVINCES
export const POST_FETCH_ALL_PROVINCES = "/admin/fetch-provinces";
export const POST_CREATE_PROVINCE = "/admin/create-province";
export const POST_FETCH_PROVINCE = "/admin/fetch-province";
export const POST_UPDATE_PROVINCE = "/admin/update-province";
export const POST_DELETE_PROVINCE = "/admin/delete-province";

//DISTRICTS
export const POST_FETCH_ALL_DISTRICTS = "/admin/fetch-districts";
export const POST_CREATE_DISTRICT = "/admin/create-district";
export const POST_FETCH_DISTRICT = "/admin/fetch-district";
export const POST_UPDATE_DISTRICT = "/admin/update-district";
export const POST_DELETE_DISTRICT = "/admin/delete-district";
export const POST_FETCH_ALL_COMMON_PROVINCES = "/common/fetch-province";

//SUBDISTRICTS
export const POST_FETCH_ALL_SUBDISTRICTS = "/admin/fetch-sub-districts";
export const POST_CREATE_SUBDISTRICT = "/admin/create-sub-district";
export const POST_FETCH_SUBDISTRICT = "/admin/fetch-sub-district";
export const POST_UPDATE_SUBDISTRICT = "/admin/update-sub-district";
export const POST_DELETE_SUBDISTRICT = "/admin/delete-sub-district";
export const POST_FETCH_ALL_COMMON_DISTRICTS = "/common/fetch-district";

//BANNERS
export const POST_FETCH_ALL_BANNERS = "/admin/fetch-banners";
export const POST_CREATE_BANNER = "/admin/create-banner";
export const POST_FETCH_BANNER = "/admin/fetch-banner";
export const POST_UPDATE_BANNER = "/admin/update-banner";
export const POST_DELETE_BANNER = "/admin/delete-banner";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

export const GET_EARNING_DATA = "/earning-charts-data";

export const GET_PRODUCT_COMMENTS = "/comments-product";

export const ON_LIKNE_COMMENT = "/comments-product-action";

export const ON_ADD_REPLY = "/comments-product-add-reply";

export const ON_ADD_COMMENT = "/comments-product-add-comment";

// CMS PAGES

export const GET_ALL_CMS_PAGES = "/cms_pages";
export const POST_CREATE_CMS_PAGE = "";
export const GET_CMS_PAGE = "/cms/show";
export const POST_UPDATE_CMS_PAGE = "/cms/update";
export const POST_DELETE_CMS_PAGE = "/cms/delete";

// Dashboard Count

export const GET_ALL_DASHBOARD_COUNT = "/dashboard/dashboard_count";