// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from 'components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { ServiceApi } from "apis/ServiceApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import i18n from "i18n";
import config from "config/config";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function Services(props) {
    const history = useHistory();
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT,
    });

    useEffect(() => {
        getAllServices(activeData);
    }, []);

    const columns = [{
        name: props.t("Sr.No"),
        selector: row => row.id,
        sortable: true,
    }, {
        name: props.t("Name"),
        selector: row => row.name,
        sortable: true
    }, {
        name: props.t("Salon Name"),
        selector: row => row.salonName,
        sortable: true
    }, {
        name: props.t("Category Name"),
        selector: row => row.categoryName,
        sortable: true
    }, {
        name: props.t("Status"),
        selector: row => row.status,
        sortable: true
    }, {
        name: props.t("Action"),
        selector: row => row.action
    }];

    const getAllServices = (data) => {
        setLoading(true);
        ServiceApi.getAllServices(data)
            .then((res) => {
                setActiveData({
                    activePage: activeData.page,
                    totalPage: res.data.data.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                res?.data?.data?.forEach((v, i) => {
                    data[i] = {
                        id: v.id,
                        name: v.name,
                        salonName: v.salonName,
                        categoryName: v.categoryName,
                        status: v.status,
                        action: (
                            <>
                                <Link
                                    style={{ marginRight: "15px" }}
                                    to={`/edit-role/${v.id}`}
                                >
                                    <i className=" fas fa-edit" id="edittooltip" />
                                </Link>
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setdata(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                ServiceApi.deleteRole(id)
                    .then((res) => {
                        Swal.fire("Deleted!", res.data.message, "success");
                        getAllServices(activeData);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const handleChange = (v) => {
        setActiveData({ activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getAllServices(data)
    }

    const handleRowChange = (v) => {
        setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllServices(data)
    }


    //meta title
    document.title = props.t("Services") + ' | ' + props.t("Salon");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Dashboard")} breadcrumbItem={props.t("Services")} />
                <Link to="/add-role" className="btn btn-primary w-xs btn-right">{props.t("Add")}</Link>
            </div>

            <div className="container-fluid">
                <DataTable
                    className="table-bordered"
                    progressPending={loading}
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={activeData.totalPage}
                    paginationPerPage={activeData.limit}
                    defaultSortFieldID={1}
                    onChangeRowsPerPage={value => handleRowChange(value)}
                    onChangePage={value => handleChange(value)}
                    sortable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    highlightOnHover
                />
            </div>
        </div>
    );
}
Services.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(Services);