import axios from "axios";
import config from "../config/config";
import * as url from "../helpers/url_helper";
import { getTimezoneInfo } from '../helpers/timezone_helper';

const { timezoneName, formattedOffset } = getTimezoneInfo();

export const AuthAPI = {
    login: async (data) => {
        return await axios.post(`${config.API_URL}${url.POST_ADMIN_LOGIN}`, data, {
            headers:{
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            }
        });
    },
    forgotPassword: async (data) => {
        return await axios.post(`${config.API_URL}/api/admin/forgot-password`, data, {
            headers:{
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            }
        });
    },
    resetPassword: async (data) => {
        return await axios.post(`${config.API_URL}/api/admin/reset-password`, data), {
            headers:{
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            }
        };
    }
}