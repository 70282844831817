import config from "../config/config";
import axios from "axios"
import { getToken } from "../Token";
import * as url from "../helpers/url_helper";
import { getTimezoneInfo } from '../helpers/timezone_helper';

const { timezoneName, formattedOffset } = getTimezoneInfo();

const token = getToken();
export const CategoryApi = {
    getAllCategories: async (data) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}${url.GET_CATEGORIES}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    createCategory: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_CREATE_CATEGORY}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getCategoryById: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.GET_CATEGORY}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    updateCategory: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_UPDATE_CATEGORY}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deleteCategory: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_DELETE_CATEGORY}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    }
}