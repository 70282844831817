export const Token = {
    saveToken: (user, token) => {
        setToken(token);
        setUserData(user);
        // setUserID(user.id);
        // storeName(user.name);
        // storeEmail(user.email);
        // storeProfilePhoto(user.profile_photo);
        // setRoleId(user.role_id);
    },
    getToken: () => {
        return getToken()
    },
    getUserID: () => {
        return getUserID()
    },
    getRoleID: () => {
        return getRoleID()
    },
    getName: () => {
        return getName()
    },
    storeName: (name) => {
        return storeName(name);
    },
    getEmail: () => {
        return getEmail()
    },
    getProfilePhoto: () => {
        return getProfilePhoto()
    },
    isLoggedIn: () => {
        if (getToken()) {
            return true;
        }
        return false;
    },
    clearStorage: () => {
        return localStorage.clear();
    }
}

export const setToken = (token) => {
    localStorage.setItem('accessToken', token);
}

export const setUserData = (user) => {
    localStorage.setItem('userInfo',JSON.stringify(user));
}

export const getToken = () => {
    return localStorage.getItem('accessToken');
}

export const isLoggedIn = () => {
    if (getToken()) {
        return true;
    }
    return false;
}

const getRoleID = () => {
    return localStorage.getItem('userInfo.role_id');
}


export const getUserID = () => {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    return userInfo ? userInfo.id : userInfo;
}


export const getName = () => {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    return userInfo ? userInfo.name : userInfo;
}

export const getEmail = () => {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    return userInfo ? userInfo.email : userInfo;
}


export const getProfilePhoto = () => {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    return userInfo ? userInfo.profile_image : userInfo;
}