import config from "../config/config";
import axios from "axios"
import { getToken } from "../Token";
import * as url from "../helpers/url_helper";
import { getTimezoneInfo } from '../helpers/timezone_helper';

const { timezoneName, formattedOffset } = getTimezoneInfo();

const token = getToken();
export const PermissionsApi = {
    getPermissionByRoleId: async (data) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}${url.GET_PERMISSIONS_BY_ROLE}/${data?.role_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    setPermissionByRoleId: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.SET_PERMISSIONS_BY_ROLE}/${data?.role_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Timezone-Name": timezoneName,
                "Timezone-Offset": formattedOffset,
            },
            data: data
        };

        return await axios(axiosConfig);
    },
  
}